<template>
    <content-view>
        <invoice></invoice>
    </content-view>
</template>

<script>
import ContentView from '@/views/menu/ContentView'
import { metaTitle } from '@/mixins/meta_title'
import session from "@/api/session";

export default {
    name: 'Settings',
    mixins: [metaTitle],
    components: {
        ContentView,
        Invoice : () => import('@/components/billing/invoice/Invoice')
    },
    // async beforeCreate () {
    //     try {
    //         const request = session.get('/api/v1/settings/access_update/');
    //         const response = await request;
    //     } catch (error) {
    //         this.$router.push('/403');
    //     }
    // }
}
</script>

<style scoped>

</style>
